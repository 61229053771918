$font-family-sans-serif: Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$sidebar-width: 250px;

$body-bg: #f2f2f2;

$primary: #11999e;
$secondary: #383838;
$danger: #ff0039;
$info: #3faae9;
$branding-dark: #0F4C81;
//$warning: #e94e1b;

$menu-text-color: #d7f7ff;
$menu-background: $primary;
$menu-header-background: $primary;

// FoxPhone theme
/*$primary: #00294e;
$secondary: #e94e1b;
$danger: #ff0039;
$warning: #e94e1b;

$menu-text-color: #cff7ff;
$menu-background: $primary;
$menu-header-background: darken($menu-background, 3);*/

// Original branding
/*
$primary: #225b54;
$secondary: #373a3c;
$danger: #ff0039;

$menu-text-color: #96bbb5;
$menu-background: $primary;
$menu-header-background: darken($menu-background, 3);
*/
