.sidebar-header > a {
  /*color: $secondary;*/
  text-align: left;
  padding-left: 35px;
  border-bottom: 1px solid #56afb3;
}

.sidebar-header img {
  height: 48px;
  float: left;
}
